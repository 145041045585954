// export const API_HOST_URL = "http://localhost:8000"
export const API_HOST_URL = "https://themediterraneanholidays.bebble.in"
export const API_URL = `${API_HOST_URL}/api`
export const IMAGE_URL = `${API_HOST_URL}`
export const ASSETS_URL = `${API_URL}/images/app_images`
export const ALL_API = {
    AUTH : {
        Login : "/account/login", 
        me : "/account/me",
        REGISTRATION_CODE: "/account/registration-code/",
        REGISTER: "/account/register/",
    },
    USER :{
        USER : "/account/user/",
        BOOKING_LIMIT : "/account/user/set_limit/",
        USER_IN_ACTIVE_COMMENT : "/account/user/set_user_in_active_comment/",
    },
    ATTACHMENT: "/hotel/attachment",
    HOTEL : {
        READ : "/hotel",
        CREATE : "/hotel/",
        BULK_UPDATE_DATE_AND_ROOMS: "/hotel/bulk_update_date_and_room/"
    },
    MASTER :{
        COUNTRY: "/master/country/",
        STATE: "/master/state/",
        GET_ALL_STATE_WITH_COUNTRY_ID: "/master/state/get_state_with_country_id",
        CITY: "/master/city/"
    },
    BOOKING: {
        BOOK: "/order/",
        MY_BOOKINGS: "/order/my_orders"
    },

    SETTINGS: {
        MAIN: "/settings/",
        update_days_before_order: "/settings/update_days_before_order/"
    }
}
